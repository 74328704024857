<template>
  <div>
    <!--begin::appointment-->
    <div>
      <h3 class="card-label">
        {{ $t('appointments.appointments_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('appointments.customer') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect
                      :class="validation && validation.customer_id ? 'is-invalid' : ''"
                      :placeholder="$t('appointments.customer')"
                      v-model="customer"
                      label="name"
                      track-by="id"
                      :options="customers"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getCustomers($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/customers/customers/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('appointments.customer') }}.</span>
                <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.customer_id[0] }}
                                </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.share_with_client') }}</label>
                <div class="input-group">
                  <!-- <label>{{$t('customers.is_default')}}:</label> -->
                  <b-form-checkbox size="lg" v-model="data.share_with_client" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.is_recurring') }}</label>
                <div class="input-group">
                  <!-- <label>{{$t('customers.is_default')}}:</label> -->
                  <b-form-checkbox size="lg" v-model="data.is_recurring" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.recurring_type') }}</label>
                <div class="input-group">
                  <select name="" id="customer" v-model="data.recurring_type" class="custom-select" :class=" validation && validation.recurring_type ? 'is-invalid' : ''">
                    <option v-for="row in recurring_types" :value="row.id" :key="row.id">
                      {{ row.title }}
                    </option>
                  </select>
                  <span v-if="validation && validation.recurring_type" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.recurring_type[0] }}
                        </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.recurring_date') }}</label>
                <div class="input-group">
                  <input type="date" v-model="data.recurring_date" class="form-control" :class=" validation && validation.recurring_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.recurring_date" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.recurring_date[0] }}
                        </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.appintment_date') }}</label>
                <input
                    type="date"
                    class="form-control"
                    :class="validation && validation.appintment_date ? 'is-invalid' : ''"
                    v-model="data.appintment_date"/>
                <span v-if="validation && validation.appintment_date" class="fv-plugins-message-container invalid-feedback">
                {{ validation.appintment_date[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.start_at') }}</label>
                <div class="input-group">
                  <input
                      type="time"
                      class="form-control"
                      v-model="data.start_at"
                  />
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.interval') }}</label>
                <div class="input-group">
                  <input
                      type="time"
                      class="form-control"
                      :class="validation && validation.interval ? 'is-invalid' : ''"
                      v-model="data.interval"/>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('appointments.procedures') }}</label>
                <div class="input-group">
                  <div class="input-group mb-3">
                    <multiselect
                        :placeholder="$t('appointments.procedures')"
                        v-model="procedure"
                        label="name"
                        track-by="id"
                        :options="procedures"
                        :multiple="false"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        @search-change="getProcedures($event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/procedures" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('appointments.procedures') }}.</span>

                <!-- <span class="form-text text-muted">Please enter your postcode</span> -->
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" v-model="data.status" class="custom-select">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12">
                  <label>{{ $t('notes') }}</label>
                  <vue-editor v-model="data.notes"></vue-editor>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
    </div>

    <div class="pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button type="reset" class="btn btn-primary mr-2" @click="save()">
            {{ $t('save') }}
          </button>
          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
    <!--end::appointment-->
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {VueEditor} from "vue2-editor";


export default {
  components: {
    VueEditor
  },
  name: "form-appointment",
  data() {
    return {

      data: {
        customer_id: "",
        appintment_date: "",
        start_at: "",
        interval: "",
        procedure_id: "",
        is_recurring: false,
        recurring_type: 1,
        recurring_date: "",
        notes: "",
        status: 1,
        share_with_client: false,
        type_type: this.$route.params.type ? this.$route.params.type : null,
        type_id: this.$route.params.type_id,
      },
      idEditing: this.$route.params.id,
      checkedValue: "",
      isEditing: false,
      customers: [],
      procedures: [],
      customer: null,
      procedure: null,
      recurring_types: [],
      status_list: [],
      validation: null,
      mainRoute: 'customers/appointments',
    };
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      } else {
        this.data.customer_id = null;
      }
    },
    procedure: function (val) {
      if (val) {
        this.data.procedure_id = val.id;
      } else {
        this.data.procedure_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    async getSalesInvoiceData() {
      if (this.data.type_id) {
        let _mainRouteId = (this.data.type_type == 'SalesInvoice' ? 'sales/sales_invoices' : 'sales/estimations');
        await ApiService.get(`${_mainRouteId}/${this.data.type_id}`).then((response) => {
          this.data.customer_id = response.data.data.customer_id;
          let _customer = this.customers.find(row => row.id == response.data.data.customer_id);
          if (_customer) {
            this.customer = _customer;
          }
        });
      }
    },
    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'appointments.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'appointments.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.customer_id = response.data.data.customer_id;
        this.customer = response.data.data.customer;
        this.data.appintment_date = response.data.data.appintment_date;
        this.data.start_at = response.data.data.start_at;
        this.data.interval = response.data.data.interval;
        this.data.procedure_id = response.data.data.procedure_id;
        this.data.share_with_client = response.data.data.share_with_client;
        this.data.is_recurring = response.data.data.is_recurring;
        this.data.recurring_type = response.data.data.recurring_type;
        this.data.recurring_date = response.data.data.recurring_date;
        this.data.status = response.data.data.status;
        this.data.notes = response.data.data.notes;
        this.procedure = response.data.data.procedure;
      });
    },

    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getRecurringType() {
      ApiService.get("base/dependency/recurring_types").then((response) => {
        this.recurring_types = response.data.data;
      });
    },
    getProcedures(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/procedures", {params: {name: filter}}).then((response) => {
          this.procedures = response.data.data;
        });
    },
    getStatus() {
      ApiService.get("base/dependency/status_list").then((response) => {
        this.status_list = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.appointments_management"), route: '/customers/appointments'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getCustomers();
    this.getProcedures();
    this.getRecurringType();
    this.getStatus();

    this.checkedValue = "active";

    if (this.idEditing) {
      this.getData();
    } else {
      let promise = this.getSalesInvoiceData();
      Promise.all([promise]).then(() => {

        this.data.appintment_date = new Date().toISOString().slice(0, 10);
        this.data.recurring_date = new Date().toISOString().slice(0, 10);

      });
    }
  },
};
</script>